import React from "react";
import { ContactPersonEntity, ContactPersonPayload } from "shared/types/user";
import { FullWidthLoader } from "../../../../parts";
import { EmptyMessage, Paper } from "../../../../ui";
import {
  ContactPersonsCardContainerWrapper,
  StyledHeader,
  StyledHeaderButton,
  Title,
} from "./ContactPersons.styled";
import ContactPersonCardContainer from "./ui/ContactPersonCard/ContactPersonCard.container";
import ContactPersonDialog from "./ui/ContactPersonDialog/ContactPersonDialog";

export type ContactPersonsProps = {
  isOpenAddContactPerson: boolean;
  setIsOpenAddContactPerson: React.Dispatch<React.SetStateAction<boolean>>;
  contactPersonsLoading: boolean;
  contactPersons: ContactPersonEntity[];
  handleAddContactPerson: (data: ContactPersonPayload) => void;
};

const ContactPersons = ({
  isOpenAddContactPerson,
  setIsOpenAddContactPerson,
  contactPersonsLoading,
  contactPersons,
  handleAddContactPerson,
}: ContactPersonsProps) => {
  return (
    <>
      <Paper>
        <StyledHeader>
          <Title variant="h3">Contact Persons</Title>
          {!contactPersonsLoading && (
            <StyledHeaderButton
              variant="outlined"
              onClick={() => setIsOpenAddContactPerson(true)}
              size="small"
            >
              Add contact person
            </StyledHeaderButton>
          )}
        </StyledHeader>
        <>
          {contactPersonsLoading ? (
            <FullWidthLoader />
          ) : contactPersons?.length ? (
            <ContactPersonsCardContainerWrapper>
              {contactPersons.map((contactPerson) => (
                <ContactPersonCardContainer
                  key={contactPerson.uuid}
                  contactPersonData={contactPerson}
                />
              ))}
            </ContactPersonsCardContainerWrapper>
          ) : (
            <EmptyMessage>You haven’t add any contact person yet.</EmptyMessage>
          )}
        </>
      </Paper>
      <ContactPersonDialog
        open={isOpenAddContactPerson}
        setOpen={setIsOpenAddContactPerson}
        title="Add contact person"
        handleSubmit={handleAddContactPerson}
      />
    </>
  );
};
export default ContactPersons;
