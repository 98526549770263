import { FullWidthLoader } from "parts";
import React from "react";
import { BucketItem, BucketItemWithImage } from "shared/types/cartItem";
import { Currency } from "shared/types/pricing";
import { EmptyCart, Pagination, Paper, Section, Title } from "ui";
import CartItemContainer from "../CartItem/CartItem.container";
import ModalGroupUpdateContainer from "../ModalGroupUpdate/ModalGroupUpdateContainer";
import Summary from "../Summary/Summary";
import Header from "../header";
import {
  CartItemsWrapper,
  Column,
  PaginationWrapper,
  Wrapper,
} from "./CartPage.styled";
import { Container } from "@mui/material";

type PaginationItem = {
  id: number;
  title: string | number;
  active: boolean;
};

type CartPageProps = {
  currency: Currency;
  downloadSdf: {
    isLoading: boolean;
    handleDownload: () => void;
    error: boolean;
  };
  totalItemsCount: number;
  pageItems: BucketItemWithImage[];
  allItems: BucketItem[];
  pagination: PaginationItem[] | null;
  loading: boolean;
  loadingRest: boolean;
  subtotal: string;
  handleChangeCount: (item: BucketItem) => Promise<void>;
  handleClickPagination: (page: number) => void;
  handleChangeWeight: (item: BucketItem) => Promise<void>;
  handleClearCart: () => Promise<void>;
  handleDelete: (uuid: string) => Promise<void>;
  handleGroupUpdate: () => void;
  quote: {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleRequestQuote: () => void;
  };
  cartInfoLoading: boolean;
  isModalGroupUpdateOpen: boolean;
  discount: number;
};

const CartPage = ({
  currency,
  downloadSdf,
  totalItemsCount,
  pageItems,
  allItems,
  pagination,
  loading,
  loadingRest,
  subtotal,
  handleChangeCount,
  handleClickPagination,
  handleChangeWeight,
  handleClearCart,
  handleDelete,
  handleGroupUpdate,
  cartInfoLoading,
  discount,
  isModalGroupUpdateOpen,
}: CartPageProps) => {
  const cartHasFeasibleCompounds = allItems?.some(
    (cartItem) => !cartItem.addedToBucket.pay.priceExists
  );

  return (
    <Section>
      <Container>
        <ModalGroupUpdateContainer
          cartItems={allItems}
          isOpen={isModalGroupUpdateOpen}
          onClose={handleGroupUpdate}
        />
        <Header
          handleClearCart={handleClearCart}
          downloadSdf={downloadSdf}
          showSelect={pageItems.length}
          totalItems={totalItemsCount}
          handleGroupUpdate={handleGroupUpdate}
        />
        {loading ? (
          <FullWidthLoader />
        ) : (
          <>
            {!totalItemsCount ? (
              <EmptyCart />
            ) : (
              <Wrapper>
                <Column main>
                  <Paper smallPaddings>
                    <Title>Items</Title>

                    <CartItemsWrapper>
                      {pageItems.map((item) => {
                        return (
                          <CartItemContainer
                            key={item.addedToBucket.uuid}
                            item={item}
                            handleChangeWeight={handleChangeWeight}
                            handleDelete={handleDelete}
                            handleChangeCount={handleChangeCount}
                            currency={currency}
                            cartInfoLoading={cartInfoLoading}
                          />
                        );
                      })}
                    </CartItemsWrapper>

                    {pagination?.length ? (
                      <PaginationWrapper mt>
                        <Pagination
                          pagination={pagination}
                          onHandleClickPagination={handleClickPagination}
                        />
                      </PaginationWrapper>
                    ) : null}
                  </Paper>
                </Column>

                <Column>
                  <Summary
                    hasFeasibleCompounds={cartHasFeasibleCompounds}
                    subtotal={subtotal}
                    loading={loadingRest || cartInfoLoading}
                    currency={currency}
                    discount={discount}
                  />
                </Column>
              </Wrapper>
            )}
          </>
        )}
      </Container>
    </Section>
  );
};

export default CartPage;
