export const SET_CATALOGS = "UTIL/SET_CATALOGS";
export const SET_CATALOG_WEIGHTS = "UTIL/SET_CATALOG_WEIGHTS";
export const SET_COUNTRIES = "UTIL/SET_COUNTRIES";
export const SET_US_STATES = "UTIL/SET_US_STATES";
export const SET_VAT_PATTERNS = "UTIL/SET_VAT_PATTERNS";
export const SET_CARRIERS = "UTIL/SET_CARRIERS";
export const RESET = "UTIL/RESET";
export const REQUEST_UTILS = "REQUEST_UTILS";
export enum GeoGroup {
  EU = "EUROPEAN_UNION",
  ValidatableVAT = "COUNTRIES_WITH_VALIDATABLE_VAT_NUMBERS",
}
