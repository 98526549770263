import { endpoints } from "../../constants";
import { customFetch } from "../../utils";
import { customFetchDelayedMiddleware } from "context/sseContext";
import { RequestMethods } from "utils/customFetch";
import { GeoGroup } from "./types";

export const fetchCountries = async () => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.GET,
    url: endpoints.GEO_COUNTRIES,
  });
};

export const fetchUsStates = async () => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.GET,
    url: endpoints.GEO_US_STATES,
  });
};

export const fetchVatPatterns = async (group: GeoGroup) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.GET,
    url: `${endpoints.GEO_VAT_PATTERNS}/${group}/vat-numbers-regexps`,
  });
};

export const fetchCountriesByGroup = async (group: GeoGroup) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.GET,
    url: `${endpoints.GEO_VAT_PATTERNS}/${group}`,
  });
};

export const fetchCatalogs = async () => {
  return await customFetch({
    url: endpoints.UTIL_CATALOGS,
  });
};

export const fetchCarriers = async () => {
  return await customFetch({
    url: endpoints.UTIL_CARRIERS,
  });
};

export const fetchCatalogWeights = async () => {
  return await customFetch({
    url: endpoints.UTIL_CATALOG_WEIGHTS,
  });
};
