import React from "react";
import { AddressEntity, AddressPayload } from "shared/types/user";
import { FullWidthLoader } from "../../../../parts";
import { EmptyMessage, Paper } from "../../../../ui";
import {
  AddressCardContainerWrapper,
  StyledHeader,
  StyledHeaderButton,
  Title,
} from "./Addresses.styled";
import AddressCardContainer from "./ui/AddressCard/AddressCard.container";
import AddressDialog from "./ui/AddressDialog/AddressDialog";

export type AddressesProps = {
  isOpenAddAddress: boolean;
  setIsOpenAddAddress: React.Dispatch<React.SetStateAction<boolean>>;
  addressesLoading: boolean;
  addresses: AddressEntity[];
  handleAddAddress: (formValues: AddressPayload) => void;
};

const Addresses = ({
  isOpenAddAddress,
  setIsOpenAddAddress,
  addressesLoading,
  addresses,
  handleAddAddress,
}: AddressesProps) => {
  return (
    <>
      <Paper>
        <StyledHeader>
          <Title variant="h3">Addresses</Title>
          {!addressesLoading && (
            <StyledHeaderButton
              variant="outlined"
              onClick={() => setIsOpenAddAddress(true)}
              size="small"
            >
              Add Address
            </StyledHeaderButton>
          )}
        </StyledHeader>
        <>
          {addressesLoading ? (
            <FullWidthLoader />
          ) : addresses?.length ? (
            <AddressCardContainerWrapper>
              {addresses.map((address) => (
                <AddressCardContainer
                  key={address.uuid}
                  addressData={address}
                />
              ))}
            </AddressCardContainerWrapper>
          ) : (
            <EmptyMessage>You haven’t added any addresses yet.</EmptyMessage>
          )}
        </>
      </Paper>
      <AddressDialog
        open={isOpenAddAddress}
        setOpen={setIsOpenAddAddress}
        title="Add new address"
        handleSubmit={handleAddAddress}
      />
    </>
  );
};

export default Addresses;
