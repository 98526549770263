import { Button } from "ui/index";
import PriceSelect from "parts/price-select/PriceSelect";
import React from "react";
import { CartIcon } from "ui/icons";
import { convertDataByConfig } from "ui/info-list/info-list";
import {
  Wrapper,
  InfoWrapper,
  Code,
  Image,
  PriceList,
  DetailsListWrapper,
  DetailsListItem,
  ImageWrapper,
  InfoChipWrapper,
} from "./CatalogGridCard.styled";
import { Chip } from "@mui/material";

export const CatalogGridCard = ({
  item,
  infoListConfig,
  code,
  handleClick,
  href,
  buttonLabel,
  readyToCart,
  isAddingToCart,
  handleAdd,
  setCurrentPrice,
  currentPrice,
  currency,
}) => {
  return (
    <Wrapper>
      <InfoChipWrapper>
        {item?.inclusion?.bindWith && (
          <Chip
            label={`SALT OF ${item?.inclusion?.bindWith}`}
            size="small"
            color="primary"
          />
        )}
      </InfoChipWrapper>

      <ImageWrapper>
        <Image
          style={{
            backgroundImage: `url(${item.image})`,
          }}
          onClick={handleClick}
          to={href}
        />
      </ImageWrapper>

      <InfoWrapper>
        <Code to={href} onClick={handleClick}>
          {code}
        </Code>
        <PriceList>
          <PriceSelect
            prices={item?.prices}
            setCurrentPrice={setCurrentPrice}
            currentPrice={currentPrice}
            currency={currency}
            label={false}
            fullWidth
          />
        </PriceList>
        <Button
          iconLeft={<CartIcon />}
          fullWidth
          size="small"
          onClick={handleAdd}
          isLoading={isAddingToCart}
          disabled={!readyToCart}
          data-test="add_to_cart_button"
        >
          {buttonLabel}
        </Button>
        <DetailsList
          className="list-wrapper"
          item={item?.product}
          config={infoListConfig}
        />
      </InfoWrapper>
    </Wrapper>
  );
};

const DetailsList = ({ item, config, className }) => {
  const list = convertDataByConfig(item, config);
  return (
    <DetailsListWrapper className={className}>
      {list.map((item) => (
        <DetailsListItem key={item.key}>
          <span>{item.title}</span>
          <span title={item.raw}>{item.value || "-"}</span>
        </DetailsListItem>
      ))}
    </DetailsListWrapper>
  );
};
