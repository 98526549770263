import React from "react";
import DialogCore from "shared/ui/DialogCore/DialogCore";
import { Typography } from "@mui/material";
import ProductReplacement from "../ProductReplacement/ProductReplacement";

type AddressDialogProps = {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  productCode: string;
  productReplacements: any[];
};

const ProductReplacementDialog = ({
  open,
  onClose,
  title,
  productCode,
  productReplacements,
}: AddressDialogProps) => {
  return (
    <DialogCore title={title} open={open} onClose={onClose} maxWidth={"sm"}>
      <Typography variant="body1" sx={{ marginBottom: "28px" }}>
        The compound {productCode} is currently out of stock, however there are
        alternative forms available for shipping right now:
      </Typography>

      {productReplacements?.map((productItem, index) => (
        <ProductReplacement
          productWithPrices={productItem}
          onClose={onClose}
          key={index}
        />
      ))}
    </DialogCore>
  );
};
export default ProductReplacementDialog;
