import superCartActions from "features/super-cart/redux/actions";
import { prepareItemToAdd } from "features/super-cart/redux/utils";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { currencySelectors } from "redux/currency";
import { BucketItem } from "shared/types/cartItem";
import { ProductTypesV2 } from "../../constants/productTypes";
import { events, useAmplitudeState } from "../../context/AmplitudeContext";
import { useCurrentPrice } from "../../hooks";
import { userSelectors } from "../../redux/user";
import { Price, ProductWithPricesDTO } from "../../typings/DTO/data-contracts";
import {
  getButtonLabel,
  makeParams,
  toastNoCartModify,
  uuid,
} from "../../utils";
import convertCatalogToCat from "../../utils/convetCatalogToCat";
import ProductCard from "./ProductCard";

type UseProductCardPropsProps = {
  item: Omit<BucketItem, "addedToBucket">;
  isRelated: boolean;
  proposal: any;
};
export const useProductCardProps = ({
  item,
  isRelated,
  proposal,
}: UseProductCardPropsProps) => {
  const { product, prices } = item;

  const { amplitude } = useAmplitudeState();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const dispatch = useDispatch();

  const [isAddingToCart, setAddingToCart] = useState(false);

  const currency = useSelector(currencySelectors.selectCurrent);

  const [currentPrice, setCurrentPrice] = useCurrentPrice({
    prices: prices,
  });

  const readyToCart = useMemo(() => !!currentPrice, [currentPrice]);

  const buttonLabel = useMemo(
    () =>
      getButtonLabel({
        catalog: product?.catalog,
        currentPrice,
      }),
    [currentPrice, product?.catalog]
  );

  const queryParams = makeParams({
    cat: convertCatalogToCat(product?.catalog),
  });
  const href = `/catalog/${product?.code}?${queryParams}`;

  const handleAdd = async () => {
    const itemToAdd = prepareItemToAdd({
      item,
      currentPrice,
      count: 1,
    });

    amplitude.logEvent(events.catalog_item_add, {
      itemtype: convertCatalogToCat(itemToAdd?.catalog),
      amount: 1,
      related: isRelated,
      Proposal: proposal,
    });

    if (isAuth) {
      saveOnServer(itemToAdd);
    } else {
      const itemLocalStorage = {
        addedToBucket: {
          count: itemToAdd.count,
          weight: itemToAdd.weight,
          uuid: uuid(),
          pay: {
            priceExists: Boolean(prices.default.price),
            pricePerOneCount: currentPrice.price,
            total: currentPrice.price * itemToAdd.count,
          },
        },
        product,
        prices,
        image: `/api/v2/catalog/all/images/by-code/${product.code}`,
      };
      saveOnLocalStorage(itemLocalStorage);
    }
  };

  // @TODO Rewrite this method and method above to work the same with List and Card
  const handleAddListItemToCart = (
    cardItem: ProductWithPricesDTO,
    selectedCardItemPrice: Price
  ) => {
    const itemToAdd = prepareItemToAdd({
      item: cardItem,
      currentPrice: selectedCardItemPrice,
      count: 1,
    });

    amplitude.logEvent(events.catalog_item_add, {
      itemtype: convertCatalogToCat(
        cardItem?.product?.catalog as ProductTypesV2
      ),
      amount: 1,
      related: isRelated,
      Proposal: proposal,
    });

    if (isAuth) {
      saveOnServer(itemToAdd);
    } else {
      const itemLocalStorage = {
        addedToBucket: {
          count: itemToAdd.count,
          weight: itemToAdd.weight,
          uuid: uuid(),
          pay: {
            priceExists: Boolean(prices.default.price),
            pricePerOneCount: currentPrice.price,
            total: currentPrice.price * itemToAdd.count,
          },
        },
        product,
        prices,
        image: `/api/v2/catalog/all/images/by-code/${product.code}`,
      };
      saveOnLocalStorage(itemLocalStorage);
    }
  };

  const saveOnServer = async (item) => {
    setAddingToCart(true);

    dispatch(
      superCartActions.postCartContent({
        items: [item],
        currency,
        onSuccess: (response) => {
          if (response) {
            if (response?.[0]) {
              toast("Product was added to cart!");
              setAddingToCart(false);
            } else if (response?.[2] === 400) {
              toastNoCartModify();
            } else {
              toast.error(response?.[1]);
              setAddingToCart(false);
            }
          }
        },
      })
    );
  };

  const saveOnLocalStorage = (item) => {
    dispatch(superCartActions.addItem(item));
    toast("Product was added to cart!");
  };

  const handleClick = () => {
    if (isRelated) {
      amplitude.logEvent(events.related_click);
    }

    if (proposal) {
      amplitude.logEvent(events.subscribe_click, {
        itemtype: convertCatalogToCat(product?.catalog),
        Proposal: proposal,
      });
    }
  };

  return {
    buttonLabel,
    currentPrice,
    setCurrentPrice,
    currency,
    readyToCart,
    href,
    handleClick,
    handleAdd,
    isAddingToCart,
    handleAddListItemToCart,
  };
};

type ProductCardContainerProps = {
  item: BucketItem;
  isRelated?: boolean;
  proposal: any;
  listMode?: any;
};

const ProductCardContainer = ({
  item = {},
  listMode,
  isRelated,
  proposal,
}: ProductCardContainerProps) => {
  const {
    buttonLabel,
    currency,
    currentPrice,
    setCurrentPrice,
    readyToCart,
    href,
    handleClick,
    handleAdd,
    isAddingToCart,
  } = useProductCardProps({
    item,
    isRelated,
    proposal,
  });

  return (
    <ProductCard
      item={item}
      handleClick={handleClick}
      code={item?.product?.code || ""}
      buttonLabel={buttonLabel}
      currentPrice={currentPrice}
      currency={currency}
      setCurrentPrice={setCurrentPrice}
      handleAdd={handleAdd}
      readyToCart={readyToCart}
      isAddingToCart={isAddingToCart}
      listMode={listMode}
      href={href}
    />
  );
};

export default ProductCardContainer;
