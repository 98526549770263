import icons from "../../ui/icons.js";
import colors from "../../ui/colors.js";
import { Wrapper } from "./Ui.styles.js";
import { Tabs } from "../../ui/index.js";
import { Route, Switch, useLocation } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard.js";
import Colors from "./pages/colors/Colors.js";
import Icons from "./pages/icons/Icons.js";
import Components from "./pages/components/Components.js";
import { Container } from "@mui/material";

const Ui = () => {
  const location = useLocation();
  const pathname = location?.pathname;

  return (
    <Wrapper className="uikit">
      <header className="uikit__header">
        <h1>UI KIT by Enamine</h1>
      </header>
      <div className="uikit__navigation">
        <Container>
          <Tabs
            navigation={
              <>
                <Tabs.Button active={pathname === "/ui"} to="/ui">
                  Dashboard
                </Tabs.Button>
                <Tabs.Button active={pathname === "/ui/colors"} to="/ui/colors">
                  Colors
                </Tabs.Button>
                <Tabs.Button active={pathname === "/ui/icons"} to="/ui/icons">
                  Icons
                </Tabs.Button>
                <Tabs.Button
                  active={pathname === "/ui/components"}
                  to="/ui/components"
                >
                  Components
                </Tabs.Button>
              </>
            }
          />
        </Container>
      </div>

      <Container>
        <Switch>
          <Route exact path={`/ui`}>
            <Dashboard />
          </Route>
          <Route exact={true} path={`/ui/colors`}>
            <Colors />
          </Route>
          <Route exact={true} path={`/ui/icons`}>
            <Icons />
          </Route>
          <Route exact={true} path={`/ui/components`}>
            <Components />
          </Route>
        </Switch>
      </Container>
    </Wrapper>
  );

  return (
    <div style={{ padding: "20px" }}>
      <h2>Colors</h2>

      {Object.keys(colors).map((key, index) => {
        const value = colors[key];

        return (
          <div key={index}>
            {key} - {value}
            <ColorBox style={{ background: value }}></ColorBox>
          </div>
        );
      })}

      <h2>Icons</h2>

      {Object.keys(icons).map((key, index) => {
        const Icon = icons[key];

        return (
          <div key={index}>
            <p>{key}</p>
            <Icon />
          </div>
        );
      })}
    </div>
  );
};

export default Ui;
