import styled from "styled-components";
import { Box, NativeSelect } from "@mui/material";
import { borderColor, darkBlue } from "ui/colors";

// @TODO Recheck all styles and rewrite if needed
export const BaseNativeSelectStyled = styled(NativeSelect)`
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 48px;
  border: 1px solid ${borderColor};
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  //padding: 0 16px;
  //padding-right: 40px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  outline: none;
  cursor: pointer;
  background-color: white;
  color: ${darkBlue};

  font-size: 16px;
  &:before,
  &:after {
    border-bottom-color: transparent !important;
  }

  &.Mui-error {
    border-color: #d50000;
  }
  & .MuiNativeSelect-icon {
    width: 16px;
    height: 16px;
    right: 16px;
  }

  option {
    color: ${darkBlue};
  }
`;

export const BaseNativeSelectWrapper = styled(Box)`
  position: relative;
`;
