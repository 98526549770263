import { takeEvery } from "redux-saga/effects";
import {
  LOGOUT,
  REQUEST_ADD_ADDRESS,
  REQUEST_ADDRESSES,
  REQUEST_ADDRESSES_AND_PERSONS,
  REQUEST_PERSONS,
  REQUEST_ADD_PERSON,
  REQUEST_DELETE_PERSON,
  REQUEST_EDIT_PERSON,
  REQUEST_PROFILE,
  REQUEST_USER,
  REQUEST_DELETE_ADDRESS,
  REQUEST_EDIT_ADDRESS,
} from "./user/types";
import {
  GET_PRODUCT_LIST,
  REQUEST_PRODUCTS_WITH_PRICES,
  SET_PAGE_SIZE,
} from "./catalog/types";
import { REQUEST_PRODUCT_WITH_PRICES } from "./product/types";
import { REQUEST_UTILS } from "./util/types";
import {
  REQUEST_ADDITIONAL_COSTS,
  REQUEST_CALCULATIONS,
  REQUEST_CONDITIONS_BY_SHIPPING_ADDRESS,
  REQUEST_PAYMENT_METHODS, REQUEST_PROMO_CODE_CALCULATIONS,
} from "./checkout/types";
import {
  requestUser,
  requestProfile,
  requestAddresses,
  logout,
  requestAddressesAndPersons,
  requestAddAddress,
  requestDeleteAddress,
  requestEditAddress,
  requestPersons,
  requestAddPerson,
  requestEditPerson,
  requestDeletePerson,
} from "./user/sagas";
import { requestProductWithPrices } from "./product/sagas";
import {
  getProductList,
  requestProductsWithPrices,
  updateCurrentItemsWithPageSize,
} from "./catalog/sagas";
import { requestUtils } from "./util/sagas";
import {
  requestAdditionalCosts,
  requestCalculations,
  requestPaymentMethods,
  requestConditionsByAddressShipping, requestPromoCodeCalculations,
} from "./checkout/sagas";

import {
  POST_CART_CONTENT,
  REQUEST_CART_DELETE_ITEMS,
  REQUEST_CART_INFO,
  REQUEST_ITEMS_AND_IMAGES,
} from "features/super-cart/redux/types";
import {
  postCartContent,
  requestCartDeleteItems,
  requestCartInfo,
  requestItemsAndImages,
} from "features/super-cart/redux/sagas";

function* sagaWatcher() {
  yield takeEvery(REQUEST_USER, requestUser);
  yield takeEvery(REQUEST_PROFILE, requestProfile);
  yield takeEvery(REQUEST_ADDRESSES, requestAddresses);
  yield takeEvery(REQUEST_PRODUCTS_WITH_PRICES, requestProductsWithPrices);
  yield takeEvery(SET_PAGE_SIZE, updateCurrentItemsWithPageSize);
  yield takeEvery(GET_PRODUCT_LIST, getProductList);
  yield takeEvery(REQUEST_PRODUCT_WITH_PRICES, requestProductWithPrices);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(REQUEST_ADDITIONAL_COSTS, requestAdditionalCosts);
  yield takeEvery(REQUEST_PAYMENT_METHODS, requestPaymentMethods);
  yield takeEvery(REQUEST_CART_INFO, requestCartInfo);
  yield takeEvery(POST_CART_CONTENT, postCartContent);
  yield takeEvery(REQUEST_ITEMS_AND_IMAGES, requestItemsAndImages);

  // V2
  yield takeEvery(REQUEST_ADDRESSES_AND_PERSONS, requestAddressesAndPersons);
  yield takeEvery(REQUEST_PERSONS, requestPersons);
  yield takeEvery(REQUEST_ADD_PERSON, requestAddPerson);
  yield takeEvery(REQUEST_EDIT_PERSON, requestEditPerson);
  yield takeEvery(REQUEST_DELETE_PERSON, requestDeletePerson);
  yield takeEvery(REQUEST_ADDRESSES, requestAddresses);
  yield takeEvery(REQUEST_ADD_ADDRESS, requestAddAddress);
  yield takeEvery(REQUEST_DELETE_ADDRESS, requestDeleteAddress);
  yield takeEvery(REQUEST_EDIT_ADDRESS, requestEditAddress);
  yield takeEvery(REQUEST_CALCULATIONS, requestCalculations);
  yield takeEvery(REQUEST_PROMO_CODE_CALCULATIONS, requestPromoCodeCalculations);
  yield takeEvery(
    REQUEST_CONDITIONS_BY_SHIPPING_ADDRESS,
    requestConditionsByAddressShipping
  );
  yield takeEvery(REQUEST_UTILS, requestUtils);

  // SUPER-CART
  yield takeEvery(REQUEST_CART_DELETE_ITEMS, requestCartDeleteItems);
}

export default sagaWatcher;
