import styled from "styled-components";

export const Header = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const SectionWrapper = styled.div`
  position: relative;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 28px;
  &:last-child {
    margin-bottom: 0;
  }
`;
