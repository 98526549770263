import React, { useState } from "react";
import { ImageWrapper } from "../ProductReplacementDialog/ProductReplacementDialog.styled";
import { Image } from "../../Product.styles";
import { getButtonLabel, isDev, toastNoCartModify } from "../../../../utils";
import { common } from "../../../../constants";
import { Box } from "@mui/material";
import InfoList from "../../../../ui/info-list/info-list";
import { CartIcon } from "../../../../ui/icons";
import { getInfoListConfigByCatalog } from "../../lib/Product.utils";
import {
  InfoListWrapper,
  LinkCode,
  ReplacementItemWrapper,
} from "./ProductReplacement.styled";
// @TODO Rethink logic, especially deps
import { prepareItemToAdd } from "../../../../features/super-cart/redux/utils";
import superCartActions from "../../../../features/super-cart/redux/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "../../../../redux/user";
import { currencySelectors } from "../../../../redux/currency";
// @TODO Replace button with MUI
import { Button } from "../../../../ui";
import { ProductWithPricesDTO } from "../../../../typings/DTO/data-contracts";
import {
  events,
  useAmplitudeState,
} from "../../../../context/AmplitudeContext";
import convertCatalogToCat from "../../../../utils/convetCatalogToCat";

type ReplacementItemProps = {
  productWithPrices: any;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProductReplacement = ({
  productWithPrices,
  onClose,
}: ReplacementItemProps) => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const currency = useSelector(currencySelectors.selectCurrent);

  const [isAddingToCart, setAddingToCart] = useState(false);

  const buttonLabel = getButtonLabel({
    catalog: productWithPrices.product?.catalog,
    currentPrice: productWithPrices.prices.default,
  });

  const handleAddToCart = async () => {
    const itemToAdd = prepareItemToAdd({
      item: {
        product: productWithPrices.product,
        prices: productWithPrices.prices,
      },
      currentPrice: productWithPrices.prices.default,
    });

    amplitude.logEvent(events.catalog_item_add, {
      itemtype: convertCatalogToCat(productWithPrices.product?.catalog),
      Proposal: ["form"],
    });

    if (isAuth) {
      saveOnServer(itemToAdd);
    } else {
      saveOnLocalStorage(itemToAdd as any);
    }
  };

  // @TODO This is the same logic that we have inside product, we have to somehow share this one between components
  const saveOnServer = async (productWithPrices: any) => {
    setAddingToCart(true);

    dispatch(
      superCartActions.postCartContent({
        items: [productWithPrices],
        currency,
        onSuccess: (response) => {
          if (response) {
            if (response?.[0]) {
              toast("Product was added to cart!");
              setAddingToCart(false);
              onClose(null);
            } else if (response?.[2] === 400) {
              toastNoCartModify();
            } else {
              toast.error(response?.[1]);
              setAddingToCart(false);
            }
          }
        },
      })
    );
  };

  const saveOnLocalStorage = (productWithPrices: ProductWithPricesDTO) => {
    dispatch(superCartActions.addItem(productWithPrices));
    toast("Product was added to cart!");
    onClose(null);
  };

  return (
    <ReplacementItemWrapper>
      <ImageWrapper>
        <Image
          style={{
            backgroundImage: isDev()
              ? `url(${common.DEV_PRODUCT_IMAGE})`
              : `url(${productWithPrices.image})`,
          }}
        />
      </ImageWrapper>
      <Box sx={{ width: "100%" }}>
        <LinkCode
          to={`/catalog/${productWithPrices.product.code}`}
          onClick={onClose}
        >
          {productWithPrices.product.code}
        </LinkCode>
        <InfoListWrapper>
          <InfoList
            item={productWithPrices.product}
            config={getInfoListConfigByCatalog(
              productWithPrices.product.catalog
            )}
            sx={{ fontSize: "14px !important" }}
          />
        </InfoListWrapper>

        <Button
          iconLeft={<CartIcon />}
          onClick={handleAddToCart}
          fullWidth
          loading={isAddingToCart.toString()}
          style={{ height: "48px" }}
        >
          {buttonLabel}
        </Button>
      </Box>
    </ReplacementItemWrapper>
  );
};

export default ProductReplacement;
