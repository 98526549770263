import React, { useMemo } from "react";
import { Loader } from "ui";
import {
  OrderTypeChip,
  OrderTypesWrapper,
  PageWrapper,
  SectionContent,
  SectionWrapper,
  Subtitle,
  Title,
  TitleWrapper,
  TrackingNumbersWrapper,
} from "./Order.styled";
import { OrderEntity } from "../lib/Order.types";
import { Box, Container } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import OrderHeader from "./OrderHeader/OrderHeader";
import { useSelector } from "react-redux";
import { utilSelectors } from "../../../../../redux/util";
import { combineAddressInfo } from "../../../../../shared/lib/Addresses.utils";
import { combinePersonInfo } from "../../../../../shared/lib/Persons.utils";
import paymentTypes from "../../../../../constants/paymentTypes";
import OrderItems from "./OrderItems/OrderItems";
import OrderStatusesStepper from "./OrderStatusesStepper/OrderStatusesStepper";
import { generateOrderStepper, generateQuoteStepper } from "../lib/Order.utils";
import OrderPricesDetailsV2 from "../../../../../shared/ui/OrderPricesDetailsV2/OrderPricesDetailsV2";
import { ShippingServiceNames } from "../../../../../shared/types/Orders.types";

type OrderProps = {
  order: OrderEntity;
  backToOrdersLink: string;
  isLoading: boolean;
  onCancelOrder: VoidFunction;
  onPayOrder: VoidFunction;
  onDownloadPdf: VoidFunction;
  onConvertToOrder: VoidFunction;
  handleGoRelatedOrderQuote: VoidFunction;
};

const Order = ({
  order,
  backToOrdersLink,
  isLoading,
  onCancelOrder,
  onPayOrder,
  onDownloadPdf,
  onConvertToOrder,
  handleGoRelatedOrderQuote,
}: OrderProps) => {
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);

  const isAnyItemInquery = order?.items?.some(
    (orderItem) => orderItem?.availableAtPurchaseTime !== "IN_STOCK"
  );

  const inStockItems = order?.items?.filter(
    (orderItem) => orderItem.availableAtPurchaseTime === "IN_STOCK"
  );
  const synthesisItems = order?.items?.filter(
    (orderItem) => orderItem.availableAtPurchaseTime !== "IN_STOCK"
  );

  const trackingNumbersString =
    order.shippingService?.clientId ??
    order?.items
      ?.flatMap((orderItem) =>
        orderItem?.trackings
          ? orderItem.trackings.map(({ tracking }) => tracking).filter(Boolean)
          : []
      )
      ?.join(", ") ??
    "";

  const steps = useMemo(() => {
    if (order?.events) {
      return order?.type === "ORDER"
        ? generateOrderStepper({
            events: order.events,
            paymentMethod: order.paymentMethod,
          })
        : generateQuoteStepper({
            events: order.events,
          });
    }

    return [];
  }, [order]);

  return (
    <Container maxWidth="lg">
      <PageWrapper>
        <OrderHeader
          orderPaymentMethod={order.paymentMethod}
          orderId={order?.storeId}
          orderTime={order?.time}
          orderType={order?.type}
          orderRelated={order?.relatedQuote || order?.relatedOrder}
          handleGoRelatedOrderQuote={handleGoRelatedOrderQuote}
          orderEventsOrder={order?.events.order}
          orderEventsPay={order?.events.pay}
          isLoading={isLoading}
          backToOrdersLink={backToOrdersLink}
          onCancelOrder={onCancelOrder}
          onPayOrder={onPayOrder}
          onDownloadPdf={onDownloadPdf}
          onConvertToOrder={onConvertToOrder}
        />

        <OrderStatusesStepper steps={steps} />

        {isLoading ? (
          <div className="order-page__grid-item order-page__grid-item--full-width">
            <SectionWrapper>
              <Loader fullWidth />
            </SectionWrapper>
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid xs={12}>
              <SectionWrapper>
                <Title variant="h5">Price</Title>
                <SectionContent>
                  <OrderPricesDetailsV2
                    price={order?.pay?.sum}
                    currencyAbbr={order?.pay?.currency}
                    isAnyItemInquery={isAnyItemInquery}
                    promoCode={order?.pay?.promoCode}
                    showPromoCode
                    type={order.type}
                  />
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Title variant="h5">Contact Person for Billing</Title>
                <SectionContent>
                  {combinePersonInfo(order.persons.billing)}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Title variant="h5">Contact Person for Shipping</Title>
                <SectionContent>
                  {combinePersonInfo(order.persons.shipping)}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Title variant="h5">Billing Address</Title>
                <SectionContent>
                  {combineAddressInfo(
                    order.addresses.billing,
                    countries,
                    usStates
                  )}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Title variant="h5">Shipping Address</Title>
                <SectionContent>
                  {combineAddressInfo(
                    order.addresses.shipping,
                    countries,
                    usStates
                  )}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <TitleWrapper>
                  <Title variant="h5">Shipping Options</Title>
                  <OrderTypesWrapper>
                    <OrderTypeChip
                      label={
                        order?.overnightDelivery
                          ? "Rush delivery"
                          : "Standard delivery"
                      }
                      variant="filled"
                      color="primary"
                    />
                    {order?.partialShipping && (
                      <OrderTypeChip
                        label="Partial Shipment"
                        variant="outlined"
                        color="primary"
                      />
                    )}
                  </OrderTypesWrapper>
                </TitleWrapper>
                <SectionContent sx={{ marginTop: "10px !important" }}>
                  <Box sx={{ marginBottom: "16px" }}>
                    {ShippingServiceNames[order.shippingService.name]}
                  </Box>
                  {trackingNumbersString && (
                    <Box sx={{ color: "#51607A", fontSize: "14px" }}>
                      <>
                        Tracking number:{" "}
                        <TrackingNumbersWrapper>
                          {trackingNumbersString}
                        </TrackingNumbersWrapper>
                      </>
                    </Box>
                  )}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Title variant="h5">Payment Type</Title>
                <SectionContent>
                  {paymentTypes?.[order?.paymentMethod]}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            {order?.comment && (
              <Grid xs={12} md={6}>
                <SectionWrapper>
                  <Title variant="h5">Comment</Title>
                  <SectionContent>{order?.comment}</SectionContent>
                </SectionWrapper>
              </Grid>
            )}

            {order?.purchaseOrderNumber && (
              <Grid xs={12} md={6}>
                <SectionWrapper>
                  <Title variant="h5">PO Number</Title>
                  <SectionContent>{order?.purchaseOrderNumber}</SectionContent>
                </SectionWrapper>
              </Grid>
            )}

            <Grid xs={12}>
              <SectionWrapper>
                <Title variant="h5" style={{ marginBottom: "28px" }}>
                  Items
                </Title>
                {Boolean(inStockItems?.length) && (
                  <>
                    <Subtitle variant="h6">In Stock</Subtitle>
                    <OrderItems
                      orderItems={inStockItems}
                      currency={order.pay.currency}
                    />
                  </>
                )}
                {Boolean(synthesisItems?.length) && (
                  <>
                    <Subtitle variant="h6">Synthesis</Subtitle>
                    <OrderItems
                      orderItems={synthesisItems}
                      currency={order.pay.currency}
                    />
                  </>
                )}
              </SectionWrapper>
            </Grid>
          </Grid>
        )}
      </PageWrapper>
    </Container>
  );
};

export default Order;
