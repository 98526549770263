import classNames from "classnames";
import React from "react";
import { Wrapper } from "./input.styles";

const Input = ({
  label,
  formik,
  name,
  type = "text",
  disabled,
  value = "",
  handleChange,
  dataTestAttr,
  className,
  ...rest
}) => {
  const splitName = name.split(".");
  const hasError = formik?.touched[name] && Boolean(formik?.errors[name]);
  const errorMessage = formik?.touched[name] && formik?.errors[name];
  const classes = classNames(
    "input",
    {
      "is-error": hasError,
      "is-disabled": disabled,
    },
    className
  );

  const inputValue =
    splitName?.length > 1
      ? formik?.values[splitName[0]][splitName[1]]
      : formik?.values[splitName[0]] ?? value;

  return (
    <Wrapper className={classes}>
      <label className="input__label" htmlFor={name} disabled={disabled}>
        {label}
      </label>
      <input
        className="input__input"
        name={name}
        id={name}
        value={inputValue}
        onChange={formik?.handleChange || handleChange}
        type={type}
        disabled={disabled}
        data-test={dataTestAttr}
        {...rest}
      />
      {errorMessage && Array.isArray(errorMessage) ? (
        <>
          {errorMessage.map((errorText, idx) => {
            return (
              <div className="input__error-message" key={idx}>
                {errorText}
              </div>
            );
          })}
        </>
      ) : (
        <div className="input__error-message">{errorMessage}</div>
      )}
    </Wrapper>
  );
};

export default Input;
