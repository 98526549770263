import React, { useEffect, useState } from "react";
import { FullWidthLoader } from "../../parts";

import { Breadcrumbs, OneProductCard, Button } from "../../ui/index";
import {
  Wrapper,
  ProductSection,
  Columns,
  Column,
  Image,
  ImageWrapper,
  ProductName,
  InfoChipWrapper,
} from "./Product.styles";
import { common } from "constants/index";
import { NotFoundIcon, ReplacementIcon, SearchIcon } from "ui/icons";
import { isEmpty } from "utils";
import { ProductGroupsContainer } from "features/product-groups/product-groups.container";
import { Chip, Container } from "@mui/material";

const NotFoundPart = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setReady(true), 300);

    return () => {
      setReady(false);
      clearTimeout(timer);
    };
  }, []);

  if (ready) {
    return (
      <div className="product-page__not-found">
        <NotFoundIcon />
        <h2>Product not found</h2>
        <p>Try using our search here or contact our team</p>
        <div className="product-page__not-found-buttons">
          <Button iconLeft={<SearchIcon />} href="/">
            Search
          </Button>
          <Button type="outlined" href={common.CONTACTS_HREF}>
            Contact us
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

const Product = ({
  loading,
  item,
  image,
  counter,
  price,
  handleAdd,
  breadcrumbs,
  isAddingToCart,
  code,
  cat,
  currency,
  showProductReplacements,
  onOpenProductReplacements,
}) => {
  return (
    <Wrapper className="product-page">
      {loading ? (
        <FullWidthLoader />
      ) : (
        <>
          <Breadcrumbs items={breadcrumbs} />
          <ProductSection>
            <Container>
              {!isEmpty(item) ? (
                <>
                  <Columns>
                    <Column image>
                      <ImageWrapper>
                        <Image
                          style={{
                            backgroundImage: `url(${image})`,
                          }}
                        />
                        <ProductName title={item.name}>{item.name}</ProductName>
                        <InfoChipWrapper>
                          {showProductReplacements && (
                            <Chip
                              label="Product replacement available"
                              size="small"
                              icon={<ReplacementIcon />}
                              onClick={onOpenProductReplacements}
                              disableRipple
                            />
                          )}
                        </InfoChipWrapper>
                      </ImageWrapper>
                    </Column>
                    <Column card>
                      <OneProductCard
                        name={item.name}
                        item={item}
                        counter={counter}
                        price={price}
                        handleAdd={handleAdd}
                        isAddingToCart={isAddingToCart}
                      />
                    </Column>
                  </Columns>
                  <ProductGroupsContainer
                    code={code}
                    cat={cat}
                    currency={currency}
                  />
                </>
              ) : (
                <NotFoundPart />
              )}
            </Container>
          </ProductSection>
        </>
      )}
    </Wrapper>
  );
};

export default Product;
