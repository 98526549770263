import PriceSelect from "parts/price-select/PriceSelect";
import React from "react";
import { BucketItemWithImage, DeliveryDecision } from "shared/types/cartItem";
import { AvailabilityStatus } from "shared/types/delivery";
import { Currency } from "shared/types/pricing";
import { getPriceText } from "shared/utils/Cart.utils";
import { CustomizedTooltip, IconButton } from "ui";
import { CloseIcon, DangerMarkIcon, InfoIcon2 } from "ui/icons";
import DebouncedInput from "../DebouncedInput/DebouncedInput";
import {
  Code,
  Column,
  DiscountPrice,
  ImageLink,
  InfoIconWrapper,
  Price,
  PriceContainer,
  PriceSelectWrapper,
  PriceWrapper,
  StockStatusWrapper,
  Wrapper,
} from "./CartItem.styled";

type CartItemProps = {
  cartItem: BucketItemWithImage;
  handleItemWeight: (item: BucketItemWithImage) => Promise<void>;
  handleItemRemove: (uuid: string) => void;
  currency: Currency;
  handleItemCount: (count: number) => void;
  cartInfoLoading: boolean;
};

const CartItem = ({
  cartItem,
  currency,
  cartInfoLoading,
  handleItemWeight,
  handleItemRemove,
  handleItemCount,
}: CartItemProps) => {
  const currentPriceAvailability =
    cartItem.prices?.all.find(
      (price) =>
        price?.weight?.amount === cartItem?.addedToBucket?.weight?.amount
    )?.delivery?.available === AvailabilityStatus.IN_STOCK
      ? "In Stock"
      : "Synthesis";

  const discount =
    Boolean(cartItem?.addedToBucket.pay.discountToPricePerOneCount) &&
    cartItem?.addedToBucket.pay.pricePerOneCount -
      cartItem?.addedToBucket.pay.discountToPricePerOneCount;

  const isDangerousGood =
    cartItem.addedToBucket?.deliveryDecision === DeliveryDecision.DANGEROUS;

  return (
    <Wrapper>
      <Column>
        <ImageLink
          style={{
            backgroundImage: `url(${cartItem?.image})`,
          }}
          alt="product"
          to={`/catalog/${cartItem.product.code}`}
        />

        <StockStatusWrapper>{currentPriceAvailability}</StockStatusWrapper>
      </Column>

      <Column>
        <Code to={`/catalog/${cartItem.product.code}`}>
          <span>{cartItem.product.code}</span>
          {isDangerousGood && (
            <CustomizedTooltip
              title="This compounds is considered a Dangerous good for shipping. 
Our team will contact you with details on lead time and costs "
              placement="bottom"
            >
              <DangerMarkIcon width={18} height={18} />
            </CustomizedTooltip>
          )}
        </Code>

        <PriceContainer>
          <PriceSelectWrapper>
            <PriceSelect
              isCartItem
              prices={cartItem?.prices}
              currency={currency}
              currentPrice={{
                weight: {
                  amount: cartItem?.addedToBucket.weight.amount,
                  measure: cartItem?.addedToBucket.weight.measure,
                },
              }}
              setCurrentPrice={(value) => {
                handleItemWeight({
                  ...cartItem,
                  addedToBucket: {
                    ...cartItem.addedToBucket,
                    weight: {
                      amount: value?.weight.amount,
                      measure: value?.weight.measure,
                    },
                  },
                });
              }}
              disabled={cartInfoLoading}
            />
          </PriceSelectWrapper>

          {Boolean(cartItem?.prices?.all?.length) && (
            <DebouncedInput
              callback={handleItemCount}
              count={cartItem.addedToBucket.count}
              hideButtons
              disabled={cartInfoLoading}
            />
          )}
        </PriceContainer>
      </Column>

      <Column>
        <PriceWrapper>
          <Price isDiscount={discount}>
            {cartItem.addedToBucket.pay.priceExists
              ? getPriceText(
                  cartItem.addedToBucket.pay.pricePerOneCount,
                  currency?.abbr
                )
              : getPriceText(cartItem.prices?.default?.price, currency?.abbr)}
          </Price>
          {discount && (
            <DiscountPrice>
              {getPriceText(discount, currency?.abbr)}
            </DiscountPrice>
          )}
          {!cartItem.addedToBucket.pay.priceExists && (
            <CustomizedTooltip
              title="Feasible compound. Price will be confirmed by our sales representative"
              placement="top"
            >
              <InfoIconWrapper>
                <InfoIcon2 width={20} height={20} />
              </InfoIconWrapper>
            </CustomizedTooltip>
          )}
        </PriceWrapper>
      </Column>

      {/* @TODO style=... Temporary solution to fix issues on design */}
      <Column>
        <IconButton
          icon={<CloseIcon />}
          onClick={() => handleItemRemove(cartItem?.addedToBucket?.uuid)}
          size="24px"
          iconSize="24px"
          disabled={cartInfoLoading}
          style={{ position: "relative", top: "-3px" }}
        />
      </Column>
    </Wrapper>
  );
};

export default CartItem;
