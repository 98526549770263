import styled from "styled-components";
import {
  accentColor,
  borderColor,
  darkBlue,
  lightBlue,
  lineColor,
  successColor,
} from "ui/colors";

import caretDown from "icons/caret-down.svg";
import caretUp from "icons/caret-up.svg";
import { Container } from "@mui/material";

export const ApiContainer = styled(Container)`
  & .container__inner {
    padding: 0 40px;
  }
`;

export const NavigationWrapper = styled.div`
  align-self: start;
  flex-basis: 316px;
  align-self: start;
  position: sticky;
  top: 116px;
  border-radius: 8px;
  padding: 28px 18px 28px 28px;
  background: ${lightBlue};

  nav {
    overflow: auto;

    max-height: calc(100vh - 200px);
    padding-right: 10px;

    scrollbar-width: thin;
    scrollbar-color: ${borderColor};
    cursor: pointer;

    ::-webkit-scrollbar {
      width: 6px;
      height: 4px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background-color: ${borderColor};
    }
  }

  nav ul {
    position: relative;
    margin: 0;
    padding: 0;
    margin-top: 18px;
  }

  nav li {
    list-style: none;
    padding: 0;
    padding-left: 20px;
    margin-bottom: 18px;
  }

  nav li:last-of-type {
    margin-bottom: 28px;
  }

  nav a {
    color: ${darkBlue};
    text-decoration: none;
  }

  nav > ul {
    margin: 0;
  }

  nav > ul > li {
    font-weight: 600;
    padding: 0;
  }

  nav ul li ul li {
    font-weight: 500;
  }

  nav ul li ul li ul li {
    font-weight: 400;
  }

  a {
    display: inline-block;
  }

  a.activeLink {
    color: ${accentColor};
  }

  summary:has(> .activeLink) {
    color: ${accentColor};
  }

  details > summary {
    width: calc(100% - 28px);
    direction: rtl;
    list-style: url(${caretDown});
  }

  details[open] > summary {
    list-style: url(${caretUp});
  }
`;

export const DocumentationWrapper = styled.main`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  scroll-behavior: smooth;
  padding-top: 28px;
  height: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: ${darkBlue};
  font-size: 16px;
  font-style: normal;
  line-height: 26px;
  font-weight: 400;

  article {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 999;
    flex-basis: 0;
    gap: 18px;
    overflow: hidden;
  }

  section {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 18px;
  }

  article a {
    color: ${accentColor};
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4 {
    padding: 0;
    margin: 0;
  }

  h1 {
    margin-top: 16px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
  }

  section:first-of-type h1 {
    margin-top: 0;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
  }

  p {
    margin: 0;
    padding: 0;
  }

  table {
    margin-bottom: 18px;
  }

  table {
    width: 100%;
    border: 1px solid ${lineColor};
    border-spacing: 0;
    border-collapse: collapse;
  }

  table thead tr {
    height: 46px;
  }

  table tbody tr {
    height: 56px;
  }

  table tr {
    border: 1px solid ${lineColor};
  }

  table th {
    text-align: left;
    background: #ebf7ff;
    padding: 8px 16px;
    border: 1px solid ${lineColor};
  }

  table td {
    padding: 14px 16px;
    border: 1px solid ${lineColor};
    vertical-align: top;
  }

  table td strong {
    color: ${successColor};
  }

  table td p {
    padding: 0;
    margin: 0;
  }

  table td pre {
    padding: 0;
    margin: 0;
    white-space: break-spaces;
  }

  table td ul {
    margin: 0;
  }

  summary {
    cursor: pointer;
  }

  pre,
  code {
    font-family: "Roboto Mono", monospace;
  }

  pre:has(> code) {
    border-radius: 8px;
    background: ${lightBlue};
    padding: 16px;
    overflow-x: auto;
  }

  td pre {
    background: unset;
    padding: unset;
    border-radius: unset;
  }

  summary > * {
    display: inline-block;
  }

  summary + * {
    margin-top: 18px;
  }
`;
