import React, { useEffect, useState } from "react";
import { CartMessage } from "ui/cart-message/cart-message";
import { useQuery } from "../../hooks";
import { useAmplitudeState } from "../../context/AmplitudeContext";
import { AmplitudeEvents } from "../../context/AmplitudeEvents";
import { common } from "constants";
import { UserMessage } from "ui/user-message/user-message";
import { Container, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { checkoutActions } from "../../redux/checkout";
import { PAYMENT_MODES } from "./model/PaymentCheck.types";
import { Header } from "./PaymentCheck.styled";

const PaymentCheck = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();

  const [isOrderPaid, setIsOrderPaid] = useState(PAYMENT_MODES.PAID);

  const handleOrdersClick = () => {
    dispatch(checkoutActions.setInitial());
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_TO_ORDERS);
  };

  const handleSearchCluck = () => {
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_TO_SEARCH);
  };

  useEffect(
    () => {
      const orderPaid = query.get("paid");
      if (orderPaid === "true") {
        setIsOrderPaid(PAYMENT_MODES.PAID);
      }

      if (orderPaid === "false") {
        setIsOrderPaid(PAYMENT_MODES.UNPAID);
      }
    },
    // eslint-disable-next-line
    []);

  return (
    <Container>
      <Header>
        <Typography variant="h1">Checkout</Typography>
      </Header>

      <CartMessage
        variant={isOrderPaid === PAYMENT_MODES.PAID ? "paid" : "unpaid"}
      >
        <CartMessage.Title>
          {isOrderPaid === PAYMENT_MODES.PAID
            ? " Thank you, your order is successfully paid"
            : "Sorry, there is currently an issue with processing payment for your order"}
        </CartMessage.Title>
        {isOrderPaid === PAYMENT_MODES.PAID ? (
          <CartMessage.Description>
            Wait for payment confirmation on your email address.
            <br />
            If you don't receive an email within a few minutes or need further
            assistance, please contact our{" "}
            <UserMessage.ExternalLink
              href={common.CONTACTS_HREF}
              target="_blank"
            >
              support team
            </UserMessage.ExternalLink>
          </CartMessage.Description>
        ) : (
          <CartMessage.Description>
            Please note that your order remains unpaid at this time. We kindly
            request that you attempt to complete the payment via your profile.
            <br />
            If the issue will persist feel free to contact us via the link
            below.
          </CartMessage.Description>
        )}
        <CartMessage.ActionWrapper>
          <CartMessage.Link to={"/profile/orders"} onClick={handleOrdersClick}>
            Check my orders
          </CartMessage.Link>
          {isOrderPaid === PAYMENT_MODES.PAID ? (
            <CartMessage.Link to={"/"} onClick={handleSearchCluck}>
              Search compounds
            </CartMessage.Link>
          ) : (
            <UserMessage.ExternalLink
              href={common.CONTACTS_HREF}
              target="_blank"
            >
              Contact our support team
            </UserMessage.ExternalLink>
          )}
        </CartMessage.ActionWrapper>
      </CartMessage>
    </Container>
  );
};

export default PaymentCheck;
