import styled from "styled-components";
import { Link } from "ui/index";
import { borderColor, darkBlue } from "ui/colors";
import { Box } from "@mui/material";

export const Wrapper = styled.div`
  background: #fff;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: inherit;
  overflow: hidden;
  position: relative;

  .list-wrapper {
    background-color: #fff;
    position: absolute;
    padding: 0 24px 24px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    top: 100%;
    right: 0;
    left: 0;
    background-color: white;
    display: flex;
    z-index: 100;
  }

  &:hover {
    z-index: 20;
    box-shadow: 0px 4px 16px 0px ${borderColor};
    overflow: visible;

    .list-wrapper {
      display: flex;
      box-shadow: 0px 10px 12px 0 ${borderColor};
    }
    &:after {
      content: "";
      position: absolute;
      background-color: white;
      bottom: 0;
      right: 0;
      left: 0;
      height: 24px;
      z-index: 20;
    }
  }
`;

export const InfoWrapper = styled.div`
  padding: 26px 24px 24px 24px;
  position: relative;
`;

export const InfoChipWrapper = styled(Box)`
  min-height: 26px;
  display: flex;
  justify-content: flex-end;
  margin: 12px 24px 10px;

  & span {
    font-size: 12px;
    font-weight: 500;
    padding: 0 12px;
  }
`;

export const ImageWrapper = styled.div`
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(Link)`
  display: block;
  background-size: contain, auto 90%;
  background-position: center;
  background-repeat: no-repeat;
  height: 136px;
  width: 100%;
  cursor: pointer;
`;

export const Code = styled(Link)`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none !important;
  word-break: break-all;
`;
export const PriceList = styled.div`
  padding: 24px 0;
`;

export const DetailsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const DetailsListItem = styled.div`
  color: ${darkBlue};
  font-size: 14px;
  font-weight: 400;
  column-gap: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    text-wrap: nowrap;
    white-space: nowrap;
  }
`;
