/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { events, useAmplitudeState } from "../../context/AmplitudeContext";
import { Section } from "../../ui";
import Content from "./Content";
import { Container } from "@mui/material";

const PrivacyPolicy = () => {
  const { amplitude } = useAmplitudeState();

  useEffect(() => {
    amplitude.logEvent(events.privacy_policy);
  }, []);

  return (
    <Section>
      <Container style={{ paddingBottom: "50px" }}>
        <h1>Privacy Policy</h1>

        <Content />
      </Container>
    </Section>
  );
};

export default PrivacyPolicy;
