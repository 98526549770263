import React from "react";
import { ReactComponent as CartIcon } from "../../icons/cart.svg";
import PriceSelect from "../../parts/price-select/PriceSelect";
import { Button } from "../index";
import {
  Wrapper,
  ImageLink,
  Content,
  ImageWrapper,
  Code,
  Footer,
} from "./ProductCard.styled";

const ProductCard = ({
  item = {},
  handleClick,
  code,
  buttonLabel,
  readyToCart,
  currentPrice,
  setCurrentPrice,
  currency,
  handleAdd,
  isAddingToCart,
  listMode,
  href,
}) => {
  return (
    <Wrapper listMode={listMode} disableInfoOnHover={false}>
      <ImageWrapper className="product-card__image-wrapper">
        <ImageLink
          style={{
            backgroundImage: `url(${item.image})`,
          }}
          onClick={handleClick}
          to={href}
          className="product-card__image"
        />
      </ImageWrapper>
      <Content className="product-card__content">
        <div className="product-card__title-with-select">
          <Code to={href} onClick={handleClick}>
            {code}
          </Code>
          {!readyToCart || !currentPrice ? (
            <div>Load prices...</div>
          ) : (
            <PriceSelect
              prices={item?.prices}
              setCurrentPrice={setCurrentPrice}
              currentPrice={currentPrice}
              currency={currency}
              label={false}
              fullWidth
            />
          )}
        </div>
        <Footer className="product-card__footer">
          <Button
            iconLeft={<CartIcon />}
            fullWidth
            size="small"
            onClick={handleAdd}
            isLoading={isAddingToCart}
            disabled={!readyToCart}
            data-test="add_to_cart_button"
          >
            {buttonLabel}
          </Button>
        </Footer>
      </Content>
    </Wrapper>
  );
};

export default ProductCard;
