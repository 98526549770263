import React, { useState } from "react";
import { Section } from "ui";
import SendEmailForm from "./parts/send-email-form/SendEmailForm";
import SendPasswordForm from "./parts/SendPasswordForm/SendPasswordForm";
import CheckEmailInfo from "./parts/check-email-info/CheckEmailInfo";
import EmailNotFound from "./parts/email-not-found";
import LinkAlreadyUsed from "./parts/link-already-used";
import LinkNotValid from "./parts/link-not-valid";
import ResetComplete from "./parts/reset-complete";
import { Switch, Route } from "react-router-dom";
import rootPages from "../../constants/rootPages";
import { Container } from "@mui/material";

const EmailForm = () => {
  const [showCheckEmail, setShowCheckEmail] = useState(false);

  return (
    <>
      {showCheckEmail ? (
        <CheckEmailInfo />
      ) : (
        <SendEmailForm setShowCheckEmail={setShowCheckEmail} />
      )}
    </>
  );
};

export default function ResetPassword() {
  return (
    <Section ySpacing>
      <Container>
        <Switch>
          <Route path={`/${rootPages.RESET_PASSWORD}/reset-complete`}>
            <ResetComplete />
          </Route>
          <Route path={`/${rootPages.RESET_PASSWORD}/email-not-found`}>
            <EmailNotFound />
          </Route>
          <Route path={`/${rootPages.RESET_PASSWORD}/link-already-used`}>
            <LinkAlreadyUsed />
          </Route>
          <Route path={`/${rootPages.RESET_PASSWORD}/link-not-valid`}>
            <LinkNotValid />
          </Route>
          <Route path={`/${rootPages.RESET_PASSWORD}/:token`}>
            <SendPasswordForm />
          </Route>
          <Route path={`/${rootPages.RESET_PASSWORD}/`}>
            <EmailForm />
          </Route>
        </Switch>
      </Container>
    </Section>
  );
}
