import { useState } from "react";
import { toast } from "react-toastify";
import TaxFreeCertificates from "./TaxFreeCertificates";
import { customFetch } from "utils";
import useFetchList from "./hooks/useFetchList";
import { useSelector } from "react-redux";
import { userSelectors } from "redux/user";
import { RequestMethods } from "../../../../utils/customFetch";
import React from "react";
import useLoaderFullPage from "../../../../features/Loaders/hooks/useLoaderFullPage";

const TaxFreeCertificatesContainer = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const { showLoaderFillPage, hideLoaderFillPage } = useLoaderFullPage();

  const list = useFetchList({ customerId: currentUser?.id });

  const handleChange = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    setModalOpen(false);
    showLoaderFillPage();

    const res = await customFetch({
      url: `customers/${currentUser?.id}/tax-free-certificates`,
      method: RequestMethods.POST,
      data: formData,
    });

    if (res[1]) {
      toast.error(res[1], {
        autoClose: 3000,
      });
    } else {
      toast.success(
        "Your certificate was successfully uploaded and it will be verified shortly.",
        {
          autoClose: 10000,
        }
      );
      await list.refetch();
    }

    hideLoaderFillPage();
  };

  const handleUploadModalClick = () => {
    setModalOpen(true);
  };

  return (
    <TaxFreeCertificates
      items={list.data}
      loading={list.loading}
      handleUploadModalClick={handleUploadModalClick}
      handleChange={handleChange}
      modal={{
        open: modalOpen,
        setOpen: setModalOpen,
      }}
    />
  );
};

export default TaxFreeCertificatesContainer;
