import React, { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import Checkout from "./Checkout";
import { CheckoutContextProvider } from "./lib/Checout.context";
import { useAmplitudeState } from "../../context/AmplitudeContext";
import { checkoutActions, checkoutSelectors } from "../../redux/checkout";
import { useDispatch, useSelector } from "react-redux";
import { currencyActions } from "../../redux/currency";
import { AmplitudeEvents } from "../../context/AmplitudeEvents";
import { useLocation } from "react-router";
import { Header } from "./Checkout.styled";

export const CheckoutContainer = () => {
  const dispatch = useDispatch();
  const { state: locationState } = useLocation<{ isQuote?: boolean }>();
  const { amplitude } = useAmplitudeState();
  const checkoutRequestData = useSelector(checkoutSelectors.selectRequestData);
  const isQuoteOrder = checkoutRequestData?.type === "QUOTE";

  useEffect(() => {
    amplitude.logEvent(AmplitudeEvents.Order.ORDER_START);
  }, [amplitude]);

  useEffect(() => {
    dispatch(checkoutActions.setInitial());
    console.log("CheckoutContainer");
    dispatch(currencyActions.setDisableSelect(false));
    return () => {
      dispatch(checkoutActions.setInitial());
      dispatch(currencyActions.setDisableSelect(false));
    };

    // eslint-disable-next-line
  }, []);

  /*
  SECTION.
  If we get on a checkout from Request Quote then we have to store type in RequestData
  Possible options - "type": "QUOTE" | "ORDER", default is "ORDER"
*/
  useEffect(
    () => {
      if (locationState?.isQuote) {
        dispatch(
          checkoutActions.setRequestData({
            data: {
              type: "QUOTE",
            },
          })
        );
      }
    },
    // eslint-disable-next-line
    [locationState]);
  /* === End === */

  return (
    <CheckoutContextProvider>
      <Container>
        <Header>
          {!isQuoteOrder && <Typography variant="h1">Checkout</Typography>}
        </Header>

        <Checkout />
      </Container>
    </CheckoutContextProvider>
  );
};
