import React from "react";
import DialogCore from "../../../../../shared/ui/DialogCore/DialogCore";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import { rootPages } from "../../../../../constants";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import RestrictedItemsTable from "./RestrictedItemsTable/RestrictedItemsTable";
import {
  DescriptionWrapper,
  Buttons,
  ButtonWrapper,
  Inner,
} from "./RestrictionDialog.styled";
import { RestrictedCartItem } from "./RestrictionDialog.types";

type RestrictionDialogProps = {
  restrictedCartItems: RestrictedCartItem[];
  onDeleteRestrictedItems: VoidFunction;
} & DialogProps;

const RestrictionDialog = ({
  restrictedCartItems,
  onDeleteRestrictedItems,
  ...rest
}: RestrictionDialogProps) => {
  const history = useHistory();

  // @TODO Rethink logic with rest.onClose(null, null);
  const handleClickBackToCart = () => {
    rest.onClose(null, null);
    history.push(rootPages.CART);
  };

  const handleClickDeleteAndProceed = () => {
    rest.onClose(null, null);
    onDeleteRestrictedItems();
  };

  return (
    <DialogCore
      title="Shipping restrictions for selected compounds"
      PaperProps={{
        sx: {
          maxWidth: "1000px",
          width: "100%",
        },
      }}
      {...rest}
    >
      <Inner>
        <DescriptionWrapper>
          Your cart contains one or more compounds which have restrictions by US
          customs regulations and cannot be shipped. Please contact support for
          more details.
        </DescriptionWrapper>

        <RestrictedItemsTable restrictedCartItems={restrictedCartItems} />
        <Buttons>
          <ButtonWrapper>
            <Button variant="outlined" onClick={handleClickBackToCart}>
              Back to cart
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button variant="contained" onClick={handleClickDeleteAndProceed}>
              Delete items and proceed
            </Button>
          </ButtonWrapper>
        </Buttons>
      </Inner>
    </DialogCore>
  );
};

export default RestrictionDialog;
