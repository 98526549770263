import React from "react";
import { Button, SearchCategories } from "ui/index";
import { MarvinImage } from "features/marvin-image";
import { PencilIcon } from "ui/icons";
import { ImageWrapper, Wrapper, Column } from "./MiddlePage.styles";
import storage from "../../../../features/marvin-image/data/storage";
import { Container } from "@mui/material";

const MiddlePage = ({ q, handleEditSearchClick }) => {
  const hasImageSrc = Boolean(storage.getItem(q));
  return (
    <Container>
      <Wrapper isColumnView={!hasImageSrc}>
        <Column>
          {hasImageSrc && (
            <ImageWrapper>
              <MarvinImage smile={q} />
            </ImageWrapper>
          )}

          <Button
            type="outlined"
            iconLeft={<PencilIcon />}
            onClick={handleEditSearchClick}
            size="small"
            fullWidth
          >
            Edit search
          </Button>
        </Column>
        <Column>
          <SearchCategories />
        </Column>
      </Wrapper>
    </Container>
  );
};

export default MiddlePage;
