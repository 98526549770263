import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../ui";
import { Wrapper, Content, Icon, Title, Text } from "./Error.styles";

const Thank = ({ error }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/profile/orders`);
  };

  return (
    <Wrapper>
      <Content>
        <Icon />
        <Title>Something went wrong!</Title>
        <Text>{error}</Text>
        <Button onClick={handleClick} fullWidth>
          My Orders
        </Button>
      </Content>
    </Wrapper>
  );
};

export default Thank;
