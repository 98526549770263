import { common, endpoints } from "constants";
import fileDownload from "js-file-download";
import { useState } from "react";
import { toast } from "react-toastify";
import { RequestMethods } from "utils/customFetch";
import { customFetch } from "../utils";

const useDownloadSdfFromCart = ({
  currency = "USD",
  isDefaultError = true,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    setError(false);

    customFetch(
      {
        method: RequestMethods.GET,
        url: `${endpoints.BUCKET_DOWNLOAD_AS_FILE}SDF?currency=${currency}`,
      },
      { baseURL: common.API_V2_URL }
    )
      .then((response) => {
        if (response[0]) {
          fileDownload(response[0], `cart-items.sdf`);
        } else {
          setError(true);
          if (isDefaultError) {
            toast.error(response[1]);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    isLoading,
    handleDownload,
    error,
  };
};

export default useDownloadSdfFromCart;
