import { StepperCheckoutStepItem } from "./Checkout.types";
import StepPersonBillingContainer from "../ui/Content/StepperCheckout/StepPersonBilling/StepPersonBilling.container";
import StepPersonShippingContainer from "../ui/Content/StepperCheckout/StepPersonShipping/StepPersonShipping.container";
import StepAddressBillingContainer from "../ui/Content/StepperCheckout/StepAddressBilling/StepAddressBilling.container";
import StepAddressShippingContainer from "../ui/Content/StepperCheckout/StepAddressShipping/StepAddressShipping.container";
import StepShippingOptionsContainer from "../ui/Content/StepperCheckout/StepShippingOptions/StepShippingOptions.container";
import {
  DhlLogoIcon,
  EnamineLogoIcon,
  FedexLogoIcon,
  UpsLogoIcon,
} from "../../../ui/icons";
import StepPaymentContainer from "../ui/Content/StepperCheckout/StepPayment/StepPayment.container";

export enum CHECKOUT_MODES {
  CHECKOUT = "CHECKOUT",
  CHECK_ORDER = "CHECK_ORDER",
  SENDING_ORDER = "SENDING_ORDER",
  THANK = "THANK",
  THANK_QUOTE = "THANK_QUOTE",
  THANK_PAY = "THANK_PAY",
  ERROR = "ERROR",
}

export const STEPPER_CHECKOUT_STEPS: Partial<StepperCheckoutStepItem>[] = [
  {
    title: "Contact Person for Shipping",
    component: StepPersonShippingContainer,
  },
  {
    title: "Contact Person for Billing",
    component: StepPersonBillingContainer,
  },
  {
    title: "Billing Address",
    component: StepAddressBillingContainer,
  },
  {
    title: "Shipping Address",
    component: StepAddressShippingContainer,
  },
  {
    title: "Shipping Options",
    component: StepShippingOptionsContainer,
  },
  {
    title: "Payment Type",
    component: StepPaymentContainer,
  },
];

export const USER_TITLES = ["Mr", "Ms", "Miss", "Mrs", "PhD", "Dr", "Prof"];

export const CarrierImages = {
  0: EnamineLogoIcon,
  2: FedexLogoIcon,
  1: DhlLogoIcon,
  4: UpsLogoIcon,
};
