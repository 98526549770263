export const getResponseErrorMessage = (error: any): string => {
  // @TODO {"message": "Person is not valid!", "details": ["Person fields 'firstName' must not be longer than 255 symbols!"]}
  // @TODO We changed order of error messages previously first was error.message and then error.details and then error.response
  const errorMsg = [
    error?.response?.data?.message,
    error?.response?.data?.details,
    error?.data?.details,
    error?.data?.message,
    error?.message,
    error?.details,
  ].filter(Boolean);

  return errorMsg.length > 0
    ? errorMsg.join(", ")
    : "Something went wrong! Please try again later.";
};
