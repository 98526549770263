import styled from "styled-components";
import { Box, Button } from "@mui/material";
import { blue } from "../../../../ui/colors";

export const CartItemsListWrapper = styled(Box)`
  & > * {
    margin-bottom: 24px;
  }
`;

export const CheckoutAdditionalInfo = styled(Box)`
  margin-top: 18px;
  text-align: center;
  color: ${blue};
  font-size: 16px;
  line-height: 24px;
  & > svg {
    position: relative;
    top: 4px;
    width: 18px;
    height: 18px;
  }
`;

export const EditCardWrapper = styled(Box)`
  position: absolute;
  top: 30px;
  right: 24px;
`;

export const Footer = styled(Box)`
  margin-top: 10px;
`;

export const PlaceOrderButton = styled(Button)`
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  margin-top: 20px;
  min-height: 60px;
`;
