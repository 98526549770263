import React from "react";
import MarvinFrame from "../../components/marvin-frame/MarvinFrame.container";
import { BackLink, Section } from "../../ui";
import { Content, Header, Title } from "./DrawSearch.styled";
import { Container } from "@mui/material";

const DrawSearch = () => {
  return (
    <Section deleteXSpacing fullHeight>
      <Content>
        <Section>
          <Container>
            <Header>
              <Title variant="h1">Structure Search</Title>
              <BackLink to="/">Back</BackLink>
            </Header>
            <MarvinFrame url="/sketch.html" width={"100%"} height={528} />
          </Container>
        </Section>
      </Content>
    </Section>
  );
};

export default DrawSearch;
