/* eslint-disable react/display-name */
import React from "react";
import { Switch, Route } from "react-router-dom";

import MainPage from "./pages/main/Main";
import ArchivedOrdersContainer from "./pages/ArchivedOrders/ArchivedOrders.container";
import AddressBookPage from "./pages/Addresses/Addresses.container";
import TaxFreeCertificatesContainer from "./pages//TaxFreeCertificates/TaxFreeCertificates.container";
import BillingContainer from "./pages/billing/Billing.container";
import rootPages from "../../constants/rootPages";
import { ProfileSidebar, Section } from "../../ui";
import { Wrapper, Spacer } from "./Profile.styles";
import CertificatesCOA from "./pages/CertificatesCOA";
import ContactPersonsContainer from "./pages/ContactPersons/ContactPersons.container";
import OrdersContainer from "./pages/OrdersV2/ui/Orders.container";
import { Container } from "@mui/material";

const routes = [
  {
    path: `/${rootPages.PROFILE}`,
    alias: rootPages.PROFILE,
    exact: true,
    main: () => <MainPage />,
  },
  {
    path: `/${rootPages.PROFILE}/orders`,
    alias: "orders",
    main: () => <OrdersContainer />,
  },
  // @TODO We have to rewrite archive orders functionality using updated OrdersContainer, temporary this solution uses previous code
  {
    path: `/${rootPages.PROFILE}/archived-orders`,
    alias: "archived-orders",
    main: () => <ArchivedOrdersContainer />,
  },
  {
    path: `/${rootPages.PROFILE}/address-book`,
    alias: "address-book",
    main: () => <AddressBookPage />,
  },
  {
    path: `/${rootPages.PROFILE}/contact-persons`,
    alias: "contact-persons",
    main: () => <ContactPersonsContainer />,
  },
  {
    path: `/${rootPages.PROFILE}/tax-free-certificates`,
    alias: "tax-free-certificates",
    main: () => <TaxFreeCertificatesContainer />,
  },
  {
    path: `/${rootPages.PROFILE}/billing`,
    alias: "billing",
    main: () => <BillingContainer />,
  },
  {
    path: `/${rootPages.PROFILE}/certificates-coa`,
    alias: "certificates-coa",
    main: () => <CertificatesCOA />,
  },
  {
    path: "*",
    alias: "",
    main: () => <>404 Not Found</>,
  },
];

const GridComponent = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <ProfileSidebar alias={route.alias} />
              </Route>
            ))}
          </Switch>
          <Spacer />
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.main />
              </Route>
            ))}
          </Switch>
        </Wrapper>
      </Container>
    </Section>
  );
};

const Profile = () => {
  return <GridComponent />;
};

export default Profile;
