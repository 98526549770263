import {
  REQUEST_PRODUCT_WITH_PRICES,
  SET_INSTOCK_EQUIVALENTS,
  SET_PRODUCT_ANALOGS,
  SET_PRODUCT_SALT_FORMS,
  SET_PRODUCT_WITH_PRICES,
} from "./types";

const requestProduct = (payload) => {
  return {
    type: REQUEST_PRODUCT_WITH_PRICES,
    payload,
  };
};

const setProductWithPrices = (payload) => {
  return {
    type: SET_PRODUCT_WITH_PRICES,
    payload,
  };
};

const setInStockEquivalents = (payload) => {
  return {
    type: SET_INSTOCK_EQUIVALENTS,
    payload,
  };
};

const setProductAnalogs = (payload) => {
  return {
    type: SET_PRODUCT_ANALOGS,
    payload,
  };
};

const setProductSaltForms = (payload) => {
  return {
    type: SET_PRODUCT_SALT_FORMS,
    payload,
  };
};

export default {
  setProductWithPrices,
  requestProduct,
  setInStockEquivalents,
  setProductAnalogs,
  setProductSaltForms,
};
