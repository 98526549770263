import {
  RESET,
  SET_CARRIERS,
  SET_CATALOGS,
  SET_CATALOG_WEIGHTS,
  SET_COUNTRIES,
  SET_US_STATES,
  SET_VAT_PATTERNS,
} from "./types";
import { transformCarriers } from "./utils";

const initialState = {
  catalogs: null,
  countries: null,
  vatPatterns: null,
  usStates: null,
  catalogWeights: null,
  carriers: null,
};

const utilReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATALOGS:
      return {
        ...state,
        catalogs: action.payload,
      };
    case SET_CARRIERS:
      return {
        ...state,
        carriers: transformCarriers(action.payload),
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_VAT_PATTERNS:
      return {
        ...state,
        vatPatterns: action.payload,
      };
    case SET_CATALOG_WEIGHTS:
      return {
        ...state,
        catalogWeights: action.payload,
      };
    case SET_US_STATES:
      return {
        ...state,
        usStates: action.payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default utilReducer;
