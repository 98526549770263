import { endpoints } from "constants";
import { customFetchDelayedMiddleware } from "context/sseContext";
import useLoaderFullPage from "features/Loaders/hooks/useLoaderFullPage";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { encode } from "utils";
import { RequestMethods } from "utils/customFetch";
import { Breadcrumbs, Flex, Title } from "../../ui";
import { PageHeader } from "../../ui/page-header/page-header.styles";
import { SubCategoryWrapper } from "./SubCategory.styled";
import { CategoryResponse } from "./SubCategoryTypes";
import SubCategoryCard from "./ui/SubCategoryCard";
import { fetchAndUpdateCategoriesByPath } from "shared/utils/Breadcrumb.utils";
import { Breadcrumb } from "shared/types/breadcrumb";
import { Container } from "@mui/material";

const SubCategory = () => {
  const [subcategoryData, setSubcategoryData] =
    useState<CategoryResponse>(null);
  const [updatedBreadcrumbItems, setUpdatedBreadcrumbItems] = useState<
    Breadcrumb[]
  >([]);
  const { path1, path2 } = useParams<{ path1: string; path2?: string }>();
  const { showLoaderFillPage, hideLoaderFillPage } = useLoaderFullPage();
  const history = useHistory();

  useEffect(() => {
    if (!path1 || !path2 || !subcategoryData?.products?.length) return;

    const finalPath = `/categories/${path1}/${path2}`;
    const productCodes = subcategoryData.products.map(({ code }) => code);
    const query = `catalog?q=${encode(productCodes)}`;

    history.push(`${finalPath}/${query}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcategoryData?.products?.length]);

  useEffect(() => {
    const fetchSubcategoryData = async () => {
      try {
        showLoaderFillPage();
        const response = await customFetchDelayedMiddleware({
          url: `${endpoints.CATALOG_CATEGORIES_BY_PATH}/${path1}${
            path2 ? `/${path2}` : ""
          }`,
          method: RequestMethods.GET,
        });

        if (response[0]) {
          setSubcategoryData(response[0] as CategoryResponse);
        }
      } finally {
        hideLoaderFillPage();
      }
    };

    fetchSubcategoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path2]);

  const breadcrumbItems = useMemo(() => {
    const paths = [];

    const updatedPathname = [path1, path2].filter(Boolean);

    updatedPathname.forEach((segment, index) => {
      const urlTitle = segment.replace(/-/g, " ");
      const title = `${urlTitle.charAt(0).toUpperCase()}${urlTitle.slice(1)}`;
      const href =
        index === updatedPathname.length - 1
          ? ""
          : `/categories/${updatedPathname.slice(0, index + 1).join("/")}`;

      paths.push({ href, title });
    });

    return paths;
  }, [path1, path2]);

  useEffect(() => {
    const updateBreadcrumbs = async () => {
      const updatedItems = await fetchAndUpdateCategoriesByPath(
        breadcrumbItems
      );
      setUpdatedBreadcrumbItems(updatedItems);
    };

    updateBreadcrumbs();
  }, [breadcrumbItems]);

  if (!subcategoryData) {
    return showLoaderFillPage();
  }

  return (
    <>
      <Breadcrumbs items={updatedBreadcrumbItems} />
      <Container>
        <PageHeader className="catalog-header">
          <Flex type="center-between">
            <Title variant="h1">{subcategoryData.name}</Title>
          </Flex>
        </PageHeader>
        {subcategoryData.subcategories && (
          <SubCategoryWrapper>
            {subcategoryData.subcategories?.map((subcategory, index) => (
              <SubCategoryCard
                key={`${subcategory?.urlName}-${index}`}
                cardItem={subcategory}
              />
            ))}
          </SubCategoryWrapper>
        )}
      </Container>
    </>
  );
};

export default SubCategory;
