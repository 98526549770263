import React, { useMemo } from "react";
import { OrderCode } from "../../Order.styled";
import { addZeroes } from "../../../../../../../utils";
import { Box } from "@mui/material";
import { CustomizedTooltip } from "../../../../../../../ui";
import { DangerMarkIcon, InfoIcon2 } from "../../../../../../../ui/icons";
import { OrderItemEntity } from "../../../lib/Order.types";
import { Currency } from "../../../../../../../shared/types/pricing";
import {
  ClarifyOrderTypo,
  ImageWrapper,
  ItemStatusChip,
  OrderInfoWrapper,
  OrderItemCode,
  OrderItemPrice,
  OrderItemWrapper,
  StatusesWrapper,
  TrackingNumbersWrapper,
  TrackingNumberTypo,
} from "./OrderItem.styled";
import { OrderItemEventsStatusConfig } from "../../../lib/Order.constants";
import { DeliveryDecision } from "../../../../../../../shared/types/cartItem";
import { OrderItemEventsStatusTypes } from "../../../../../../../shared/types/Orders.types";

type OrderItemProps = {
  orderItem: OrderItemEntity;
  currency: Currency;
};

const OrderItem = ({ orderItem, currency }: OrderItemProps) => {
  const isDangerousGood =
    orderItem?.deliveryDecision === DeliveryDecision.DANGEROUS;

  const orderItemEventsConfig = useMemo(() => {
    const eventsConfig = orderItem?.events?.map(
      (event) => OrderItemEventsStatusConfig[event.status]
    );

    return eventsConfig ?? [];
  }, [orderItem?.events]);

  const trackingNumbers = useMemo(() => {
    return orderItem?.trackings?.map(({ tracking }) => tracking).join(", ");
  }, [orderItem.trackings]);

  const isClarifyOrder = orderItem?.events?.some(
    (event) =>
      event.status === OrderItemEventsStatusTypes.ITEM_SYNTHESIS_FAILED ||
      event.status === OrderItemEventsStatusTypes.ITEM_ACTION_REQUIRED
  );

  return (
    <OrderItemWrapper>
      <div className="g-flex g-flex-vertical-center">
        <ImageWrapper>
          <img
            alt={`Product - ${orderItem?.product.code}`}
            src={orderItem?.image}
          />
        </ImageWrapper>

        <OrderInfoWrapper>
          <OrderItemCode>
            <Box sx={{ display: "flex" }}>
              <OrderCode>{orderItem?.product.code}</OrderCode>
              {isDangerousGood && (
                <CustomizedTooltip
                  title="This compounds is considered a Dangerous good for shipping. 
Our team will contact you with details on lead time and costs "
                  placement="bottom"
                >
                  <DangerMarkIcon />
                </CustomizedTooltip>
              )}
              <Box sx={{ marginLeft: "6px" }}>
                {orderItem?.count} x {orderItem?.weight.amount}{" "}
                {orderItem?.weight.measure} x{" "}
                {addZeroes(orderItem?.pay.pricePerOneCountAtPurchaseTime)}{" "}
                {currency}
                {orderItem?.product.purity && (
                  <span>, Purity: {orderItem?.product.purity}%</span>
                )}
              </Box>
            </Box>

            <OrderItemPrice>
              {addZeroes(orderItem?.pay.total)} {currency}
              {orderItem?.availableAtPurchaseTime === "SYNTHESIS" && (
                <CustomizedTooltip
                  title="Feasible compound. Price will be confirmed by our sales representative"
                  placement="top"
                >
                  <InfoIcon2 width={18} height={18} />
                </CustomizedTooltip>
              )}
            </OrderItemPrice>
          </OrderItemCode>
          <StatusesWrapper>
            {orderItemEventsConfig?.length ? (
              orderItemEventsConfig?.map((orderItemEventConfig) => (
                <ItemStatusChip
                  label={orderItemEventConfig.label?.toUpperCase()}
                  variant="outlined"
                  color={orderItemEventConfig.color as any}
                  size="small"
                />
              ))
            ) : (
              <ItemStatusChip
                label="NEW"
                variant="outlined"
                color="primary"
                size="small"
              />
            )}

            {trackingNumbers && (
              <TrackingNumberTypo variant="body1">
                Tracking number:{" "}
                <TrackingNumbersWrapper>
                  {trackingNumbers}
                </TrackingNumbersWrapper>
              </TrackingNumberTypo>
            )}

            {isClarifyOrder && (
              <ClarifyOrderTypo variant="body1">
                Our team will contact you to clarify details on your order soon.
              </ClarifyOrderTypo>
            )}
          </StatusesWrapper>
        </OrderInfoWrapper>
      </div>
    </OrderItemWrapper>
  );
};

export default OrderItem;
