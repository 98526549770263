import React from "react";
import { Loader } from "ui";
import { Wrapper } from "./SendingOrder.styled";

const SendingOrder = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

export default SendingOrder;
