import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { toastNoCartModify, uuid } from "../../utils";
import { productActions, productSelectors } from "../../redux/product";
import { useCounter, useCurrentPrice, useQuery } from "../../hooks";
import { userSelectors } from "../../redux/user";
import { events, useAmplitudeState } from "../../context/AmplitudeContext";

import { getTextSearchType, getCatalogType } from "../../utils";
import { currencySelectors } from "redux/currency";
import superCartActions from "features/super-cart/redux/actions";
import { prepareItemToAdd } from "features/super-cart/redux/utils";
import { ProductTypesV2 } from "../../constants/productTypes";
import convertCatalogToCat from "../../utils/convetCatalogToCat";
import ProductReplacementDialog from "./ui/ProductReplacementDialog/ProductReplacementDialog";

const initialBreadcrumbs: any = [
  {
    href: "/search",
    title: "Search",
  },
];

const ProductContainer = () => {
  const { amplitude } = useAmplitudeState();
  const query = useQuery();
  const { id: code } = useParams<{ id: string }>();
  const catQuery = query.get("cat");
  const type = getTextSearchType(code);
  const cat = catQuery || getCatalogType(code);
  const dispatch = useDispatch();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const currency = useSelector(currencySelectors.selectCurrent);
  const productWithPrices = useSelector((state) =>
    productSelectors.selectProduct(state, {
      catalog: ProductTypesV2[cat],
      code,
    })
  );

  const { product, prices, image } = productWithPrices;

  const [currentPrice, setCurrentPrice] = useCurrentPrice({
    prices: prices,
  });
  const counter = useCounter();
  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const [isAddingToCart, setAddingToCart] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isReplacementsDialogOpen, setIsReplacementsDialogOpen] =
    useState(false);

  const productReplacements = useMemo(() => {
    return productWithPrices?.included?.forms
      ?.filter((includedItem: any) =>
        includedItem?.inclusion?.details?.includes("SALT_FORMS_AND_TAUTOMERS")
      )
      .map((includedItem) => ({
        ...includedItem,
        image: `/api/v2/catalog/all/images/by-code/${includedItem.product.code}`,
      }));
  }, [productWithPrices]);

  const showProductReplacements = useMemo(() => {
    return (
      productReplacements?.length &&
      currentPrice?.weight?.available === "SYNTHESIS"
    );
  }, [productReplacements, currentPrice]);

  const fetchProduct = () => {
    dispatch(
      productActions.requestProduct({
        code,
        type,
        cat,
        includeAdditionalResults: true,
        onSuccess: () => {
          setLoading(false);
        },
      })
    );
  };

  const saveOnServer = async (item) => {
    setAddingToCart(true);

    dispatch(
      superCartActions.postCartContent({
        items: [item],
        currency,
        onSuccess: (response) => {
          if (response) {
            if (response?.[0]) {
              toast("Product was added to cart!");
              setAddingToCart(false);
            } else if (response?.[2] === 400) {
              toastNoCartModify();
            } else {
              toast.error(response?.[1]);
              setAddingToCart(false);
            }
          }
        },
      })
    );
  };

  const saveOnLocalStorage = (item) => {
    dispatch(superCartActions.addItem(item));
    toast("Product was added to cart!");
  };

  const handleAdd = async () => {
    const itemToAdd = prepareItemToAdd({
      item: { product: { catalog: ProductTypesV2[cat], code } },
      currentPrice,
      count: counter.count,
    });

    amplitude.logEvent(events.catalog_item_add, {
      itemtype: convertCatalogToCat(product?.catalog),
      amount: counter?.count,
    });

    if (isAuth) {
      saveOnServer(itemToAdd);
    } else {
      const itemLocalStorage = {
        addedToBucket: {
          count: itemToAdd.count,
          weight: itemToAdd.weight,
          uuid: uuid(),
          pay: {
            priceExists: Boolean(prices.default.price),
            pricePerOneCount: currentPrice.price,
            total: currentPrice.price * itemToAdd.count,
          },
        },
        product,
        prices,
        image: `/api/v2/catalog/all/images/by-code/${product.code}`,
      };
      saveOnLocalStorage(itemLocalStorage);
    }
  };

  const handleOpenProductReplacements = () => {
    setIsReplacementsDialogOpen(true);
    amplitude.logEvent(events.product_show_replacement);
  };

  useEffect(() => {
    if (currency && code) {
      fetchProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, currency]);

  useEffect(() => {
    if (code) {
      setLoading(true);

      setBreadcrumbs([
        ...initialBreadcrumbs,
        {
          title: code,
        },
      ]);
    }
  }, [code]);

  useEffect(() => {
    if (product?.code === code) {
      amplitude.logEvent(events.product_details_opened, {
        itemtype: convertCatalogToCat(product?.catalog),
      });
    }
  }, [product, amplitude, code]);

  return (
    <>
      <Product
        loading={loading}
        item={product}
        image={image}
        counter={counter}
        handleAdd={handleAdd}
        breadcrumbs={breadcrumbs}
        isAddingToCart={isAddingToCart}
        price={{
          currentPrice,
          currency,
          prices,
          setCurrentPrice,
        }}
        code={code}
        cat={cat}
        currency={currency}
        showProductReplacements={showProductReplacements}
        onOpenProductReplacements={handleOpenProductReplacements}
      />
      <ProductReplacementDialog
        productCode={product?.code}
        title="In-Stock Alternative available"
        open={isReplacementsDialogOpen}
        onClose={() => setIsReplacementsDialogOpen(false)}
        productReplacements={productReplacements}
      />
    </>
  );
};

export default ProductContainer;
